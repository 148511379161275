<template>
  <div>
    <h3>
      Alamat
      <b-button
        variant="gradient-danger"
        class="btn-icon rounded-circle mb-1"
        @click="showModalNewAlamat"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
    </h3>
    <b-overlay :show="showOverlay">
      <div>
        <b-table
          small
          :fields="fields"
          :items="localDaftarAlamat"
          responsive="sm"
        >
          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(action)="data">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon rounded-circle"
              size="sm"
              @click="deleteAlamat(data.index)"
            >
              <feather-icon icon="DeleteIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon rounded-circle ml-1"
              size="sm"
              @click="showModalEditAlamat(data.index)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </template>

          <template #cell(jenis_alamat)="{ item, value }">
            <!-- {{ item.jenis_alamat_id }}
          {{ value }}
          {{ value.nama }} -->
            <!-- <div v-if="item.jenis_alamat_id === '1'">
            satu
          </div> -->
            <b-badge v-if="item.jenis_alamat_id === '1'" pill variant="success">
              {{ value.nama }}
            </b-badge>
            <b-badge v-if="item.jenis_alamat_id === '2'" pill variant="warning">
              {{ value.nama }}
            </b-badge>
            <b-badge v-if="item.jenis_alamat_id === '3'" pill variant="primary">
              {{ value.nama }}
            </b-badge>
          </template>
          <!-- A custom formatted column -->
          <template #cell(name)="data">
            {{ data.value.first + ' - ' + data.value.last }}
          </template>

          <template #cell(alamat)="data">
            {{ data.value }}
          </template>

          <template #cell(propinsi)="data">
            {{ data.value }}
          </template>

          <template #cell(kab_kota)="data">
            {{ data.value }}
          </template>

          <!-- A custom formatted column -->
          <template #cell(Popularity)="data">
            <b-progress
              :value="data.value.value"
              max="100"
              :variant="data.value.variant"
              striped
            />
          </template>

          <!-- A virtual composite column -->
          <template #cell(price)="data">
            {{ '$' + data.value }}
          </template>

          <!-- Optional default data cell scoped slot -->
          <template #cell()="data">
            {{ data.value }}
          </template>
        </b-table>
      </div>
      <template #overlay>
        <div v-if="isProcessing" class="text-center">
          <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p><strong id="form-confirm-label">Are you sure?</strong></p>
          <div class="d-flex">
            <b-button
              variant="outline-danger"
              class="mr-3"
              @click="onOverlayCancel"
            >
              Cancel
            </b-button>
            <b-button variant="outline-success" @click="onOverlayOk">
              OK
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
    <b-modal
      id="modal-alamat"
      ref="modal-alamat"
      scrollable
      title="Alamat"
      cancel-title="Close"
      ok-title="Accept"
      cancel-variant="outline-secondary"
      @ok="handleModalOk"
    >
      <!-- <template #modal-ok="{ ok }">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-0 mr-0"
          size="sm"
          :disabled="btnSimpanDisabled"
          @click="ok()"
        >
        <b-spinner
            small
            type="grow"
            v-show="btnSimpanSpinnerShow"
          />
          <span class="sr-only">Loading...</span>
          Simpan
        </b-button>
      </template> -->
      <template #modal-footer="{ ok, cancel }">
        <!-- <b>Custom Footer</b> -->
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          :disabled="btnSimpanDisabled"
          @click="ok()"
        >
          Simpan
          <b-spinner
            v-show="btnSimpanSpinnerShow"
            small
            type="grow"
            class="ml-1"
          />
          <span class="sr-only">Loading...</span>
        </b-button>
        <!-- Button with custom close trigger value -->
        <!-- <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Forget it
        </b-button> -->
      </template>
      <form ref="alamat-form" @submit.stop.prevent="simpanAlamat">
        <b-form-group label="Jenis Alamat" label-for="jenis-alamat">
          <b-form-select
            v-model="selectedAlamat.jenis_alamat_id"
            :options="optionsJenisAlamat"
          />
        </b-form-group>
        <b-form-group label="Nama" label-for="nama">
          <b-form-input
            id="nama"
            v-model="selectedAlamat.nama"
            placeholder="Nama"
          />
        </b-form-group>
        <b-form-group label="Alamat" label-for="alamat">
          <b-form-textarea
            id="alamat"
            v-model="selectedAlamat.alamat"
            placeholder="Tulis Alamat"
          />
        </b-form-group>
        <b-form-group label="Propinsi" label-for="propinsi">
          <b-form-select
            v-model="selectedAlamat.propinsi_id"
            :options="optionsPropinsi"
            class="mb-2"
            @change="handleProvinceSelection"
          />
        </b-form-group>
        <b-form-group label="Kab/Kota" label-for="kabkota">
          <b-form-select
            v-model="selectedAlamat.kab_kota_id"
            :options="optionsKabKota"
            class="mb-2"
          />
        </b-form-group>
        <b-form-group label="Kode Pos" label-for="kodepos">
          <b-form-input
            id="kode_pos"
            v-model="selectedAlamat.kode_pos"
            placeholder="Kode Pos"
          />
        </b-form-group>
        <b-row>
          <b-col md="6">
            <b-form-group label="Telepon" label-for="telepon">
              <b-form-input
                id="telepon"
                v-model="selectedAlamat.telepon"
                placeholder="Telepon"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Faksimili" label-for="faksimili">
              <b-form-input
                id="faksimili"
                v-model="selectedAlamat.faksimili"
                placeholder="Faksimili"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
      <!-- <b-card-text>
        Test content
      </b-card-text> -->
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BProgress,
  BBadge,
  BButton,
  // BCardText,
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BSpinner,
  BCol,
  BRow,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getCacheableApi, api } from '@/hap-sia/setup'

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BButton,
    // BCardText,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BSpinner,
    BCol,
    BRow,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: ['masterDaftarAlamat', 'biodataId'],
  data() {
    return {
      masterJenisAlamat: null,
      masterPropinsi: null,
      masterKabKota: null,
      optionsJenisAlamat: [{ value: null, text: '--Pilih Jenis Alamat--' }],
      optionsPropinsi: [{ value: null, text: '--Pilih Propinsi--' }],
      optionsKabKota: [{ value: null, text: '--Pilih KabKota--' }],
      localDaftarAlamat: this.masterDaftarAlamat,
      jenisAlamatId: 1,
      selectedAlamat: null,
      selectedIndex: null,
      btnSimpanSpinnerShow: false,
      btnSimpanDisabled: false,
      showOverlay: false,
      isProcessing: false,
      indexToBeDeleted: null,
      fields: [
        // A virtual column that doesn't exist in items
        'index',
        // A regular column
        { key: 'jenis_alamat', label: 'Jenis' },
        // A column that needs custom formatting
        { key: 'nama', label: 'Nama' },
        {
          key: 'alamat',
          label: 'Alamat',
          formatter: (value, key, item) => {
            let alamat = ''
            let propinsi = ''
            let kabkota = ''
            if (item.propinsi) {
              propinsi = item.propinsi.nama
            }
            if (item.kab_kota) {
              kabkota = `${item.kab_kota.nama},`
            }
            if (item.alamat) {
              alamat = `${item.alamat},`
            }
            return `${alamat} ${kabkota} ${propinsi}`
          },
        },
        // A regular column
        // { key: 'propinsi_id', label: 'Propinsi' },
        // {
        //   key: 'propinsi',
        //   label: 'Propinsi',
        //   formatter: (value, key, item) => {
        //     console.log('BBBBBBB')
        //     if (item.propinsi) {
        //       return item.propinsi.nama
        //     }
        //     return ''
        //   },
        // },
        // A virtual column made up from two fields
        // {
        //   key: 'kab_kota',
        //   label: 'Kab/Kota',
        //   formatter: (value, key, item) => {
        //     if (item.kab_kota) {
        //       return item.kab_kota.nama
        //     }
        //     return ''
        //   },
        // },
        // A virtual column that doesn't exist in items
        'Action',
      ],
      items: [
        {
          name: { first: 'Fitbit', last: 'Activity Tracker' },
          Category: 'Fitness',
          order_status: { status: 'Delivered', variant: 'success' },
          Popularity: { value: 50, variant: 'success' },
          price: 99.99,
        },
        {
          name: { first: 'Fitbit ', last: 'Flex Wireless Activity' },
          Category: 'Fitness',
          order_status: { status: 'Pending', variant: 'primary' },
          Popularity: { value: 36, variant: 'primary' },
          price: 89.85,
        },
        {
          name: { first: 'Fitbit', last: 'Sleep Tracker Wristband' },
          Category: 'Fitness',
          order_status: { status: 'Delivered', variant: 'success' },
          Popularity: { value: 76, variant: 'success' },
          price: 65.25,
        },
        {
          name: { first: 'Fitbit', last: 'Sleep Wristband' },
          Category: 'Fitness',
          order_status: { status: 'On Hold', variant: 'warning' },
          Popularity: { value: 55, variant: 'warning' },
          price: 75.55,
        },
        {
          name: {
            first: 'Fitbit',
            last: 'Clip for Zip Wireless Activity Trackers',
          },
          Category: 'Fitness',
          order_status: { status: 'Canceled', variant: 'danger' },
          Popularity: { value: 75, variant: 'danger' },
          price: 105.55,
        },
      ],
    }
  },
  created() {
    this.selectedAlamat = this.createNewAlamat()
  },
  async mounted() {
    const cacheApi = await getCacheableApi()
    try {
      const results = await cacheApi.get('master/jenis-alamat')
      this.masterJenisAlamat = results.data
      console.log('master jenis alamat:')
      console.log(this.masterJenisAlamat)
      for (let i = 0; i < this.masterJenisAlamat.length; i += 1) {
        this.optionsJenisAlamat.push({
          value: this.masterJenisAlamat[i].id,
          text: this.masterJenisAlamat[i].nama,
        })
      }
      const results2 = await cacheApi.get('master/propinsi')
      this.masterPropinsi = results2.data
      for (let i = 0; i < this.masterPropinsi.length; i += 1) {
        this.optionsPropinsi.push({
          value: this.masterPropinsi[i].id,
          text: this.masterPropinsi[i].nama,
        })
      }
    } catch (error) {
      alert(error)
    }
  },
  methods: {
    createNewAlamat() {
      return {
        jenis_alamat_id: '1',
        nama: null,
        alamat: null,
        propinsi_id: null,
        kab_kota_id: null,
        kode_pos: null,
        telepon: null,
        faksimili: null,
      }
    },
    async handleProvinceSelection() {
      // alert(`province selectet ${this.selectedAlamat.propinsi_id}`)
      this.selectedAlamat.kab_kota_id = null
      this.optionsKabKota = [{ value: null, text: 'Loading...' }]
      const cacheApi = await getCacheableApi()
      const resp = await cacheApi.get(
        `master/propinsi/${this.selectedAlamat.propinsi_id}/kabkota`,
      )

      this.optionsKabKota = [{ value: null, text: '-- Pilih Kab/Kota --' }]

      resp.data.forEach(element => {
        this.optionsKabKota.push({
          value: element.id,
          text: element.nama,
        })
      })
    },
    // async addAlamat() {
    //   const cacheApi = await getCacheableApi()

    //   this.items.push({
    //     name: { first: 'mantap', last: 'jiwa' },
    //     Category: 'Fitness',
    //     order_status: { status: 'Canceled', variant: 'danger' },
    //     Popularity: { value: 75, variant: 'danger' },
    //     price: 105.55,
    //   })
    // },
    showModalNewAlamat() {
      this.selectedIndex = null
      this.selectedAlamat = this.createNewAlamat()

      this.$refs['modal-alamat'].show()
      // this.$refs['modal-alamat'].title = 'Mantap'
    },
    async showModalEditAlamat(index) {
      this.$refs['modal-alamat'].show()
      // this.$refs['modal-alamat'].title = 'Mantap'
      this.selectedAlamat = JSON.parse(
        JSON.stringify(this.localDaftarAlamat[index]),
      )
      this.selectedIndex = index
      const tmpKabKota = this.selectedAlamat.kab_kota_id
      if (this.selectedAlamat.propinsi_id) {
        try {
          this.selectedAlamat.kab_kota_id = null
          this.optionsKabKota = [{ value: null, text: '-- Loading... --' }]
          const cacheApi = await getCacheableApi()
          const resp = await cacheApi.get(
            `master/propinsi/${this.selectedAlamat.propinsi_id}/kabkota`,
          )

          this.optionsKabKota = [{ value: null, text: '-- Pilih Kab/Kota --' }]

          resp.data.forEach(element => {
            this.optionsKabKota.push({
              value: element.id,
              text: element.nama,
            })
          })
          this.selectedAlamat.kab_kota_id = tmpKabKota
        } catch (error) {
          alert(error)
        }
      }
    },
    async simpanAlamat() {
      // alert(this.selectedAlamat.id)
      // this.selectedAlamat.mantap = 'mantap'
      // check apakah sudah ada biodataId
      if (this.biodataId) {
        // jika punya biodata ID, langsung kita simpan ke db
        try {
          if (this.selectedAlamat.id) {
            delete this.selectedAlamat.kab_kota
            delete this.selectedAlamat.propinsi
            await api().patch('biodata/alamat', this.selectedAlamat)
          } else {
            this.selectedAlamat.biodata_id = this.biodataId
            const result = await api().post(
              'biodata/alamat',
              this.selectedAlamat,
            )
            this.selectedAlamat.id = result.data.id
          }
        } catch (error) {
          alert(error)
          this.btnSimpanDisabled = false
          this.btnSimpanSpinnerShow = false
          return false
        }
      }
      // tambahkan info nama propinsi dan kabkota
      const kabkota = this.optionsKabKota.find(
        item => item.value === this.selectedAlamat.kab_kota_id,
      )
      this.selectedAlamat.kab_kota = { nama: kabkota.text }
      const propinsi = this.optionsPropinsi.find(
        item => item.value === this.selectedAlamat.propinsi_id,
      )
      this.selectedAlamat.propinsi = { nama: propinsi.text }
      const jenisAlamat = this.optionsJenisAlamat.find(
        item => item.value === this.selectedAlamat.jenis_alamat_id,
      )
      this.selectedAlamat.jenis_alamat = { nama: jenisAlamat.text }
      // check apakah kita sedang edit existing data dari localDaftarAlamat
      if (this.selectedIndex != null) {
        this.localDaftarAlamat.splice(
          this.selectedIndex,
          1,
          this.selectedAlamat,
        )
      } else {
        this.localDaftarAlamat.push(this.selectedAlamat)
      }

      // if (this.selectedAlamat.id) {
      //   // artinya kita sedang edit existing alamat, jadi langsung kita simpan ke db
      //   try {
      //     const result = await api.patch('biodata', {
      //       biodata_id: this.selectedAlamat.biodata_id,
      //       daftar_alamat: [this.selectedAlamat],
      //     })
      //   } catch (error) {
      //     alert(error)
      //   }
      // }
      // if (this.selectedIndex != null) {
      //   // alert(this.selectedIndex)
      //   // console.log(this.selectedAlamat)
      //   // this.localDaftarAlamat[this.selectedIndex] = this.selectedAlamat
      //   this.localDaftarAlamat.splice(this.selectedIndex, 1, this.selectedAlamat)
      // } else {
      //   this.localDaftarAlamat.push(this.selectedAlamat)
      // }

      this.selectedIndex = null
      // this.selectedAlamat = null
      // alert('Berhasil simpan alamat')
      this.btnSimpanDisabled = false
      this.btnSimpanSpinnerShow = false
      // Hide the modal manually
      this.$nextTick(() => {
        // this.$bvModal.hide('modal-prevent-closing')
        this.$refs['modal-alamat'].hide()
      })

      return true
    },
    async handleModalOk(bvModalEvt) {
      this.btnSimpanDisabled = true
      this.btnSimpanSpinnerShow = true
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.simpanAlamat()
    },
    deleteAlamat(index) {
      this.showOverlay = true
      this.indexToBeDeleted = index
      // alert(index)
      // delete this.localDaftarAlamat[index]
      // this.localDaftarAlamat.splice(index, 1)
    },
    onOverlayCancel() {
      this.showOverlay = false
      this.indexToBeDeleted = null
    },
    // overlay OK will delete item
    async onOverlayOk() {
      this.isProcessing = true
      // await new Promise(r => setTimeout(r, 2000))
      const toBeDeleted = this.localDaftarAlamat[this.indexToBeDeleted]
      try {
        await api().delete(`biodata/alamat/${toBeDeleted.id}`)
      } catch (error) {
        alert(error.response.data)
        return
      }
      this.localDaftarAlamat.splice(this.indexToBeDeleted, 1)
      this.indexToBeDeleted = null
      this.showOverlay = false
      this.isProcessing = false
    },
  },
}
</script>
