<template>
  <div>
    <!-- {{masterBiodata}} -->
    <b-card no-body class="text-center mt-0">
      <template #header>
        <b-row class="w-100">
          <b-col cols="8" class="text-left">
            <h4 class="mb-0">
              Pengakhiran
            </h4>
          </b-col>
          <b-col cols="4" class="text-right">
            <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
          </b-col>
        </b-row>
      </template>
      <b-card-body class="text-left">
        <b-row class="mb-1">
          <b-col>
            <b-table
              responsive
              :items="daftarRiwayat"
              :fields="fields"
              :busy="isBusy"
              class="mt-1"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>
              <template #cell(id)="data">
                {{ data.value }}
              </template>
              <template #cell(alasan)="data">
                {{ data.value.nama }}
              </template>
              <template #cell(action)="{ item }">
                <div class="text-nowrap text-center">
                  <!-- Dropdown -->
                  <b-dropdown variant="link" toggle-class="p-0" no-caret>
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <!-- <b-dropdown-item>
                      <feather-icon icon="DownloadIcon" />
                      <span class="align-middle ml-50">Download</span>
                    </b-dropdown-item> -->
                    <!-- <b-dropdown-item
                      :to="{
                        name: 'apps-invoice-edit',
                        params: { id: data.item.id },
                      }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="editRiwayat(item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Delete</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item>
                      <feather-icon icon="CopyIcon" />
                      <span class="align-middle ml-50">Duplicate</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-body>
      <template v-if="showSimpan" #footer>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="12" class="text-left">
              <b-button
                v-if="showSimpan() && !masterBiodata.is_pengakhiran"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="simpan"
              >
                Lakukan Pengakhiran
              </b-button>
              <b-button
                v-if="showSimpan() && masterBiodata.is_pengakhiran"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="cabut"
              >
                Cabut Pengakhiran
              </b-button>
              <b-button
                v-if="showSimpan()"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="ml-2"
                @click="addRiwayat"
              >
                Tambah Riwayat Pengakhiran
              </b-button>
            </b-col>
            <!-- <b-col cols="4" class="text-right">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="confirmDelete"
              >
                Hapus
              </b-button>
            </b-col> -->
          </b-row>
        </b-container>
      </template>
      <b-overlay :show="showOverlay" no-wrap>
        <template #overlay>
          <div v-if="isProcessing" class="text-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p><strong id="form-confirm-label">Are you sure?</strong></p>
            <div class="d-flex">
              <b-button
                variant="outline-danger"
                class="mr-3"
                @click="onOverlayCancel"
              >
                Cancel
              </b-button>
              <b-button variant="outline-success" @click="onOverlayOk">
                OK
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>
    <b-row>
      <b-col>
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDaftarBuktiPengakhiran"
          :biodata-i-d="masterBiodata.id"
          :config="{ initWithJenisDokumen: '43', allowAddNew: true }"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BButton,
  BTable,
  BRow,
  BCol,
  //   BFormGroup,
  //   BFormInput,
  BOverlay,
  BSpinner,
  BContainer,
  BDropdown,
  BDropdownItem,
  // BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import emitter from '@/hap-sia/emitter'
import { api } from '@/hap-sia/setup'
import moment from 'moment'
import BiodataDaftarDokumen from '@/views/hap/shared/BiodataDaftarDokumen.vue'

export default {
  // inject: ['emitter'],
  components: {
    BCard,
    // BCardHeader,
    BCardBody,
    // BCardText,
    BButton,
    BTable,
    BRow,
    BCol,
    // BFormGroup,
    // BFormInput,
    BOverlay,
    BSpinner,
    BContainer,
    BDropdown,
    BDropdownItem,
    BiodataDaftarDokumen,
    // BBadge,
    // BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: ['masterBiodata'],
  data() {
    return {
      showOverlay: false,
      isProcessing: false,
      isBusy: false, // utk spinner table
      fields: [
        // { key: 'id', label: 'ID' },
        {
          key: 'tanggal',
          label: 'Tanggal',
          formatter: value => {
            moment.locale('id')
            if (!value) {
              return ''
            }
            return moment(value).format('LL')
          },
        },
        { key: 'alasan', label: 'Alasan' },
        { key: 'catatan', label: 'Catatan' },
        { key: 'action', label: 'Action' },
      ],
      items: [
        {
          id: '123',
          biodata_id: '123',
          tanggal: '2021-01-01',
          alasan_id: '1',
          alasan: { nama: 'Dipecat' },
          catatan: 'suka-suka',
          created_at: '2021-01-01',
        },
      ],
      daftarRiwayat: [],
      masterDaftarBuktiPengakhiran: [],
    }
  },
  async mounted() {
    console.log('PENGAKHIRAN')
    console.log(this.masterBiodata.daftar_dokumen[0].data)
    this.masterBiodata.daftar_dokumen.forEach(element => {
      console.log('ahai')
      if (element.jenis_dokumen_id === '43') {
        console.log(element)
        this.masterDaftarBuktiPengakhiran.push(element)
      }
    })
    emitter.on('RIWAYAT_PENGAKHIRAN_UPDATED', async () => {
      this.reload()
    })
    try {
      await this.reload()
    } catch (error) {
      alert(error)
    }
  },
  methods: {
    async reload() {
      this.isBusy = true
      // kosongkan array riwayat
      this.daftarRiwayat.splice(0, this.daftarRiwayat.length)
      const result = await api().get(
        `keanggotaan/riwayat/pengakhiran/${this.masterBiodata.id}`,
      )
      result.data.forEach(element => {
        this.daftarRiwayat.push(element)
      })
      this.isBusy = false
    },
    showSimpan() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (
        userData.default_role === 'user' ||
        userData.default_role === 'member'
      ) {
        return false
      }

      return true
    },
    async simpan() {
      // alert('Not implemented yet')
      emitter.emit('BIODATA_REQUEST_PENGAKHIRAN', {
        masterBiodata: this.masterBiodata,
      })
    },
    async cabut() {
      // alert('Not implemented yet')
      emitter.emit('BIODATA_REQUEST_CABUT_PENGAKHIRAN', {
        masterBiodata: this.masterBiodata,
      })
    },
    async addRiwayat() {
      // alert('Not implemented yet')
      emitter.emit('BIODATA_REQUEST_CREATE_RIWAYAT_PENGAKHIRAN', {
        masterBiodata: this.masterBiodata,
      })
    },
    async editRiwayat(row) {
      // console.log('EDIT RIWAYAT')
      // console.log(row)
      // alert('Not implemented yet')
      emitter.emit('BIODATA_REQUEST_UPDATE_RIWAYAT_PENGAKHIRAN', {
        masterBiodata: this.masterBiodata,
        riwayat_pengakhiran: row,
      })
    },
  },
}
</script>
