<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          e-KTA
          <small class="text-muted">Kartu Tanda Anggota Elektronik</small>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="mt-2">
          <b-col cols="3">
            <div class="mb-2">
              <b-avatar :src="avatarFile" square rounded="sm" size="13rem" />
            </div>
          </b-col>
          <b-col cols="9">
            <dl class="row">
              <dt class="col-sm-5">
                Nama
              </dt>
              <dd class="col-sm-7">
                <h4>
                  {{ masterBiodata.nama_lengkap }}
                </h4>
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-5">
                Nomor Induk Anggota (NIA)
              </dt>
              <dd class="col-sm-7">
                <h3>
                  <b-badge variant="light-primary">
                    {{ masterBiodata.nia }}
                  </b-badge>
                </h3>
              </dd>
            </dl>
            <!-- <dl class="row">
              <dt class="col-sm-5">
                Tanggal NIA diberikan
              </dt>
              <dd class="col-sm-7">
                <h3>
                  <b-badge variant="light-primary">
                    {{ masterBiodata.tanggal_nia }}
                  </b-badge>
                </h3>
              </dd>
            </dl> -->
            <!-- <dl class="row">
          <dt />
          <dd class="col-sm-9 ml-auto">
            Donec id elit non mi porta gravida at eget metus.
          </dd>
        </dl> -->
            <dl class="row">
              <dt class="col-sm-5">
                Masa Berlaku
              </dt>
              <dd class="col-sm-7">
                <h3>
                  <b-badge variant="light-primary">
                    {{ localTanggalBerakhir }}
                  </b-badge>
                </h3>
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-5 text-truncate">
                Status Keanggotaan
              </dt>
              <dd class="col-sm-7">
                <h3>
                  <b-badge
                    v-if="statusKeanggotaan === 1"
                    pill
                    variant="light-success"
                  >
                    Aktif
                  </b-badge>
                  <b-badge
                    v-if="statusKeanggotaan === 2"
                    pill
                    variant="light-danger"
                  >
                    Tidak Aktif - Masa Keanggotaan Berakhir
                  </b-badge>
                  <b-badge
                    v-if="statusKeanggotaan === 3"
                    pill
                    variant="light-danger"
                  >
                    Tidak Aktif - Pengakhiran
                  </b-badge>
                  <b-badge
                    v-if="statusKeanggotaan === 4"
                    pill
                    variant="light-warning"
                  >
                    Advokat - Non Anggota
                  </b-badge>
                  <b-badge
                    v-if="statusKeanggotaan === 5"
                    pill
                    variant="light-info"
                  >
                    Tidak Diketahui
                  </b-badge>
                  <b-badge
                    v-if="statusKeanggotaan === 6"
                    pill
                    variant="light-primary"
                  >
                    Calon Advokat
                  </b-badge>
                  <b-badge
                    v-if="statusKeanggotaan === 7"
                    pill
                    variant="light-dark"
                  >
                    BAS Belum Diunggah
                  </b-badge>
                </h3>
              </dd>
            </dl>
          </b-col>
        </b-row>

        <!-- <dl class="row">
          <dt class="col-sm-3">
            Nesting
          </dt>
          <dd class="col-sm-9">
            <dl class="row">
              <dt class="col-sm-4">
                Nested definition list
              </dt>
              <dd class="col-sm-8">
                Aenean posuere, tortor sed cursus feugiat, nunc augue blandit
                nunc.
              </dd>
            </dl>
          </dd>
        </dl> -->
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  //   BCardText,
  BCardHeader,
  BCardBody,
  BAvatar,
  BBadge,
} from 'bootstrap-vue'
// import { api } from '@/hap-sia/setup'
import moment from 'moment'
import emitter from '@/hap-sia/emitter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    // BCardText,
    BCardHeader,
    BCardBody,
    BAvatar,
    BBadge,
  },
  props: ['masterBiodata', 'masterFotos'],
  data() {
    return {
      avatarFile: null,
    }
  },
  computed: {
    statusKeanggotaan() {
      // alert('computed called')
      return this.getStatusKeanggotaan()
    },
    localTanggalBerakhir() {
      return this.getLocalTanggalBerakhir()
    },
  },
  mounted() {
    // this.getAvatar()
    emitter.on('AVATAR_UPDATED', async e => {
      this.avatarFile = e.file
    })
  },
  methods: {
    // async getAvatar() {
    //   try {
    //     const result = await api().get(
    //       `biodata/image/${this.masterBiodata.id}/${this.masterFotos[0].data.file}`,
    //     )
    //     console.log(result)
    //     this.fileAvatar = result.data.url
    //   } catch (error) {
    //     // alert(error)
    //     console.log(error)
    //   }
    // },
    getStatusKeanggotaan() {
      // const dataTemp = this.dataAnggota.find(testt => testt.id === value)
      const isAdvokat = this.masterBiodata.is_advokat
      const isPengakhiran = this.masterBiodata.is_pengakhiran
      const nia = this.masterBiodata.nia ? this.masterBiodata.nia : null
      const tanggalBerakhir = this.masterBiodata.tanggal_berakhir
        ? moment(this.masterBiodata.tanggal_berakhir).format('YYYY-MM-DD')
        : this.masterBiodata.tanggal_berakhir
      const today = moment(new Date()).format('YYYY-MM-DD')
      let returnValue

      if (
        // Aktif
        isAdvokat === true &&
        (nia !== null || nia !== '') &&
        tanggalBerakhir !== null &&
        tanggalBerakhir !== '' &&
        tanggalBerakhir > today &&
        (isPengakhiran === false || isPengakhiran === null)
      ) {
        returnValue = 1
      }
      if (
        // Tidak Aktif - Habis Masa Berlaku
        isAdvokat === true &&
        (nia !== null || nia !== '') &&
        tanggalBerakhir !== null &&
        tanggalBerakhir !== '' &&
        tanggalBerakhir < today &&
        (isPengakhiran === false || isPengakhiran === null)
      ) {
        returnValue = 2
      }
      if (
        // Tidak Aktif - Pengakhiran
        isAdvokat === true &&
        (nia !== null || nia !== '') &&
        isPengakhiran === true
      ) {
        returnValue = 3
      }
      if (
        // Advokat - Non Anggota
        isAdvokat === true &&
        // && nia === null
        (nia === null || nia === '')
      ) {
        returnValue = 4
      }
      if (
        // Tidak Diketahui
        isAdvokat === true &&
        nia !== null &&
        nia !== '' &&
        (isPengakhiran === null || isPengakhiran === false) &&
        (tanggalBerakhir === null || tanggalBerakhir === '')
      ) {
        returnValue = 5
      }
      if (
        // Calon Advokat
        (isAdvokat === null || isAdvokat === false) &&
        (nia === null || nia === '')
      ) {
        returnValue = 6
      }
      if (
        // BAS Belum Diunggah
        (isAdvokat === null || isAdvokat === false) &&
        nia !== null &&
        nia !== ''
      ) {
        returnValue = 7
      }

      return returnValue
    },
    getLocalTanggalBerakhir() {
      moment.locale('id')
      if (!this.masterBiodata.tanggal_berakhir) {
        return ''
      }
      return moment(this.masterBiodata.tanggal_berakhir).format('LL')
    },
  },
}
</script>
