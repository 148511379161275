<template>
  <b-sidebar
    id="sbPengakhiran"
    ref="sbPengakhiran"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <b-overlay :show="show" rounded="sm">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ title }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <!-- {{dataSidebar}} -->
          <!-- <b-form-group
            label="Pengakhiran"
            label-for="forPengakhiran"
          >
            <v-select
              v-model="comboPengakhiran"
              :options="opsiPengakhiran"
              placeholder="Pilih Pengakhiran"
              input-id="pengakhiran"
              :clearable="true"
            />
          </b-form-group> -->

          <b-form-group label="Tanggal" label-for="forTanggal">
            <flat-pickr
              v-model="riwayat_pengakhiran.tanggal"
              class="form-control"
            />
          </b-form-group>
          <!-- {{localData}} -->
          <!-- <b-form-group
            v-if="localData.todo === 'lakukanpengakhiran'"
            label="Alasan"
            label-for="forAlasan"
          >
            <v-select
              v-model="comboAlasan"
              :options="opsiAlasan"
              placeholder="Pilih Alasan"
              input-id="alasan"
              :clearable="true"
            />
          </b-form-group> -->
          <b-form-group v-if="showAlasan" label="Alasan" label-for="alasan">
            <b-form-select
              v-model="selectedAlasan"
              :options="optionsAlasan"
              class="mb-2"
            />
          </b-form-group>

          <!-- Internal Payment Note -->
          <b-form-group label="Catatan" label-for="forCatatan">
            <b-form-textarea
              v-model="riwayat_pengakhiran.catatan"
              placeholder="Catatan Pengakhiran"
              rows="5"
              trim
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="showBtnPengakhiran"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="doPengakhiran"
            >
              Save
            </b-button>
            <b-button
              v-if="showBtnUpdate"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="doPengakhiran"
            >
              Update
            </b-button>
            <b-button
              v-if="showBtnCabut"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              @click="doPengakhiran"
            >
              Aktifkan Kembali
            </b-button>
            <b-button
              v-if="localData.tanggal_berakhir"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mr-2"
              type="submit"
              @click="reset"
            >
              Reset
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
        <template v-slot:overlay>
          <div
            v-if="processing"
            class="text-center p-4 bg-primary text-light rounded"
          >
            <b-icon icon="cloud-upload" font-scale="4" />
            <div class="mb-3">
              Processing...
            </div>
            <b-spinner class="align-middle" />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p><strong id="form-confirm-label">Are you sure?</strong></p>
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mr-3"
                @click="onCancel"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-success"
                @click="onOK"
              >
                OK
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormTextarea,
  BButton,
  BOverlay,
  BFormSelect,
  BSpinner,
  BIcon,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import emitter from '@/hap-sia/emitter'
import { getCacheableApi, api } from '@/hap-sia/setup'

export default {
  // inject: ['emitter'],
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BButton,
    // vSelect,
    BOverlay,
    flatPickr,
    BFormSelect,
    BSpinner,
    BIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sideId: this.idSidebar,
      show: false,
      busy: false,
      processing: false,
      optionsAlasan: [{ value: null, text: '--Pilih Alasan--' }],
      selectedAlasan: null,
      riwayat_pengakhiran: {
        tanggal: null,
        catatan: null,
        alasan_id: null,
      },
      localData: {
        sidebarTitle: null,
      },
      mode: null, // nilainya bisa pengakhiran, cabut, dst
      title: 'Pengakhiran',
      showBtnPengakhiran: false,
      showBtnCabut: false,
      showBtnUpdate: false,
      localBiodata: null,
      showAlasan: true,
    }
  },
  async mounted() {
    const cacheApi = await getCacheableApi()
    emitter.on('BIODATA_REQUEST_PENGAKHIRAN', async e => {
      this.localBiodata = e.masterBiodata
      this.$root.$emit('bv::toggle::collapse', 'sbPengakhiran')
      this.selectedAlasan = null
      this.optionsAlasan = [{ value: null, text: 'Loading...' }]
      const resp = await cacheApi.get('master/alasan-pengakhiran')

      this.optionsAlasan = [{ value: null, text: '-- Pilih Alasan --' }]

      resp.data.forEach(element => {
        if (element.id === '99') {
          return
        }
        this.optionsAlasan.push({
          value: element.id,
          text: element.nama,
        })
      })
      this.title = 'Pengakhiran'
      this.mode = 'pengakhiran'
      this.showBtnPengakhiran = true
      this.showBtnCabut = false
      this.showBtnUpdate = false
      this.showAlasan = true
      this.riwayat_pengakhiran.tanggal = null
      this.riwayat_pengakhiran.catatan = null
    })
    emitter.on('BIODATA_REQUEST_CABUT_PENGAKHIRAN', async e => {
      this.localBiodata = e.masterBiodata
      this.$root.$emit('bv::toggle::collapse', 'sbPengakhiran')
      this.selectedAlasan = '99'
      this.optionsAlasan = [{ value: '99', text: 'Aktif Kembali' }]
      this.title = 'Cabut Pengakhiran'
      this.mode = 'cabut'
      this.showBtnPengakhiran = false
      this.showBtnCabut = true
      this.showBtnUpdate = false
      this.showAlasan = false
      this.riwayat_pengakhiran.tanggal = null
      this.riwayat_pengakhiran.catatan = null
    })
    emitter.on('BIODATA_REQUEST_CREATE_RIWAYAT_PENGAKHIRAN', async e => {
      this.localBiodata = e.masterBiodata
      this.$root.$emit('bv::toggle::collapse', 'sbPengakhiran')
      this.selectedAlasan = null
      this.optionsAlasan = [{ value: null, text: 'Loading...' }]
      const resp = await cacheApi.get('master/alasan-pengakhiran')

      this.optionsAlasan = [{ value: null, text: '-- Pilih Alasan --' }]

      resp.data.forEach(element => {
        this.optionsAlasan.push({
          value: element.id,
          text: element.nama,
        })
      })
      this.title = 'Tambah Riwayat Pengakhiran'
      this.mode = 'riwayat'
      this.showBtnPengakhiran = true
      this.showBtnCabut = false
      this.showBtnUpdate = false
      this.showAlasan = true
      this.riwayat_pengakhiran.tanggal = null
      this.selectedAlasan = null
      this.riwayat_pengakhiran.catatan = null
    })
    emitter.on('BIODATA_REQUEST_UPDATE_RIWAYAT_PENGAKHIRAN', async e => {
      this.localBiodata = e.masterBiodata
      this.$root.$emit('bv::toggle::collapse', 'sbPengakhiran')
      this.optionsAlasan = [{ value: null, text: 'Loading...' }]
      const resp = await cacheApi.get('master/alasan-pengakhiran')

      this.optionsAlasan = [{ value: null, text: '-- Pilih Alasan --' }]

      resp.data.forEach(element => {
        this.optionsAlasan.push({
          value: element.id,
          text: element.nama,
        })
      })
      this.title = 'Rubah Riwayat Pengakhiran'
      this.mode = 'edit'
      this.showBtnUpdate = true
      this.showBtnPengakhiran = false
      this.showBtnCabut = false
      this.showAlasan = true
      this.riwayat_pengakhiran.id = e.riwayat_pengakhiran.id
      this.riwayat_pengakhiran.tanggal = e.riwayat_pengakhiran.tanggal
      this.selectedAlasan = e.riwayat_pengakhiran.alasan_id
      this.riwayat_pengakhiran.catatan = e.riwayat_pengakhiran.catatan
    })
  },
  methods: {
    onCancel() {
      this.show = false
    },
    async onOK() {
      try {
        this.processing = true
        if (this.mode === 'pengakhiran') {
          await api().post('keanggotaan/pengakhiran', {
            biodata_id: this.localBiodata.id,
            riwayat_pengakhiran: {
              tanggal: this.riwayat_pengakhiran.tanggal,
              alasan_id: this.selectedAlasan,
              catatan: this.riwayat_pengakhiran.catatan,
            },
          })
          this.localBiodata.is_pengakhiran = true
          this.makeToast('success', 'Sukses', 'Pengakhiran berhasil!')
          this.$root.$emit('bv::toggle::collapse', 'sbPengakhiran')

          emitter.emit('RIWAYAT_PENGAKHIRAN_UPDATED', {
            biodata_id: this.localBiodata.id,
            tanggal: this.riwayat_pengakhiran.tanggal,
            alasan_id: this.selectedAlasan,
            catatan: this.riwayat_pengakhiran.catatan,
          })
        }
        if (this.mode === 'cabut') {
          console.log('CABUT')
          await api().post('keanggotaan/pengakhiran/cabut', {
            biodata_id: this.localBiodata.id,
            riwayat_pengakhiran: {
              tanggal: this.riwayat_pengakhiran.tanggal,
              catatan: this.riwayat_pengakhiran.catatan,
            },
          })
          this.localBiodata.is_pengakhiran = false
          this.makeToast('success', 'Sukses', 'Pencabutan berhasil!')
          this.$root.$emit('bv::toggle::collapse', 'sbPengakhiran')

          emitter.emit('RIWAYAT_PENGAKHIRAN_UPDATED', {
            biodata_id: this.localBiodata.id,
            tanggal: this.riwayat_pengakhiran.tanggal,
            alasan_id: '99',
            catatan: this.riwayat_pengakhiran.catatan,
          })
        }
        if (this.mode === 'riwayat') {
          console.log('add RIWAYAT')
          const result = await api().post('keanggotaan/riwayat/pengakhiran', {
            biodata_id: this.localBiodata.id,
            tanggal: this.riwayat_pengakhiran.tanggal,
            catatan: this.riwayat_pengakhiran.catatan,
            alasan_id: this.selectedAlasan,
          })
          this.makeToast('success', 'Sukses', 'Data berhasil disimpan!')
          this.$root.$emit('bv::toggle::collapse', 'sbPengakhiran')
          emitter.emit('RIWAYAT_PENGAKHIRAN_UPDATED', result.data)
        }
        if (this.mode === 'edit') {
          console.log('EDIT RIWAYAT')
          // alert('Not implemented yet')
          const result = await api().patch('keanggotaan/riwayat/pengakhiran', {
            id: this.riwayat_pengakhiran.id,
            biodata_id: this.localBiodata.id,
            tanggal: this.riwayat_pengakhiran.tanggal,
            catatan: this.riwayat_pengakhiran.catatan,
            alasan_id: this.selectedAlasan,
          })
          this.makeToast('success', 'Sukses', 'Data berhasil disimpan!')
          this.$root.$emit('bv::toggle::collapse', 'sbPengakhiran')
          emitter.emit('RIWAYAT_PENGAKHIRAN_UPDATED', result.data)
        }
      } catch (error) {
        // console.log(error)
        this.makeToast('danger', 'Error', error.response.data.message, true)
        this.show = false
      }
      this.show = false
      this.processing = false
    },
    reset() {
      this.busy = true
    },
    doPengakhiran() {
      this.show = true
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
