<template>
  <div>
    <!-- {{masterDaftarDokumen}} -->
    <!-- collapse -->
    <app-collapse id="daftar_dokumen" accordion type="margin" class="mt-0">
      <app-collapse-item
        v-for="(dok, index) in masterDaftarDokumen"
        :key="index"
        :title="dok.id"
        :is-visible="true"
      >
        <template #header>
          <b-container fluid class="w-100">
            <b-row>
              <b-col cols="8" class="text-left">
                <span class="lead collapse-title">{{
                  dok.jenis_dokumen ? dok.jenis_dokumen.nama : dok.id
                }}</span>
              </b-col>
              <b-col cols="4" class="text-right">
                <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
                <verification-status
                  v-if="dok.id && !dok.verification_status"
                  :status="'0'"
                />
                <verification-status v-else :status="dok.verification_status" />
              </b-col>
            </b-row>
          </b-container>
        </template>
        <!-- {{dok}} -->
        <form-foto v-if="dok.jenis_dokumen_id === '22'" :master-dokumen="dok" />
        <form-dokumen :master-dokumen="dok" />
        <template #footer>
          <b-container fluid class="w-100">
            <b-row>
              <b-col cols="9" class="text-left">
                <b-button
                  v-if="showSimpan(dok)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="simpan(index)"
                >
                  Simpan
                </b-button>
                <b-button
                  v-if="showVerifikasi()"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="verifikasiDokumen(index)"
                >
                  Verifikasi
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info"
                  class="mr-1"
                >
                  Lihat Log
                </b-button>
              </b-col>
              <b-col cols="3" class="text-right">
                <b-button
                  v-if="showHapus(dok)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="ml-1"
                  @click="confirmDelete(index)"
                >
                  Hapus
                </b-button>
              </b-col>
            </b-row>
          </b-container>
          <b-overlay :show="showOverlay" no-wrap>
            <template #overlay>
              <div v-if="isProcessing" class="text-center">
                <b-spinner variant="primary" label="Text Centered" />
              </div>
              <div
                v-else
                ref="dialog"
                tabindex="-1"
                role="dialog"
                aria-modal="false"
                aria-labelledby="form-confirm-label"
                class="text-center p-3"
              >
                <p><strong id="form-confirm-label">Are you sure?</strong></p>
                <div class="d-flex">
                  <b-button
                    variant="outline-danger"
                    class="mr-3"
                    @click="onOverlayCancel"
                  >
                    Cancel
                  </b-button>
                  <b-button variant="outline-success" @click="onOverlayOk">
                    OK
                  </b-button>
                </div>
              </div>
            </template>
          </b-overlay>
        </template>
      </app-collapse-item>
    </app-collapse>
    <!--/ collapse -->
    <div v-if="allowAddNew" class="mt-3">
      <b-form-select
        v-model="selectedJenisDokumen"
        :options="optionsJenisDokumen"
        class="mb-1"
      />
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="tambahDokumenBaru"
      >
        Tambah Dokumen Baru
      </b-button>
    </div>
  </div>
</template>
<script>
import {
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import { getCacheableApi, api, upload } from '@/hap-sia/setup'
import emitter from '@/hap-sia/emitter'
import AppCollapse from './AppCollapse.vue'
import AppCollapseItem from './AppCollapseItem.vue'
import FormDokumen from './FormDokumen.vue'
import VerificationStatus from './VerificationStatus.vue'
import FormFoto from './FormFoto.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BContainer,
    BRow,
    BCol,
    // BBadge,
    BButton,
    BFormSelect,
    BOverlay,
    BSpinner,
    FormDokumen,
    VerificationStatus,
    FormFoto,
  },
  directives: {
    Ripple,
  },
  props: ['masterDaftarDokumen', 'biodataID', 'config', 'masterBiodata'],
  data() {
    return {
      selectedJenisDokumen: null,
      optionsJenisDokumen: null,
      masterJenisDokumen: null,
      showOverlay: false,
      isProcessing: false,
      indexToBeDeleted: null,
      localDaftarDokumen: [],
      allowAddNew: true,
      disableDelete: false,
    }
  },
  created() {
    // const sampleConfig = {
    //   singleItem: false,
    //   singleJenisDokumen: null, // atau jenis_dokumen_id
    // }
    console.log('CONFIG')
    console.log(this.config)

    // let clone masterDaftarDokumen
  },
  async mounted() {
    const cacheApi = await getCacheableApi()
    try {
      const resp = await cacheApi.get('master/jenis-dokumen')
      this.optionsJenisDokumen = [
        { value: null, text: '-- Pilih Jenis Dokumen --' },
      ]
      this.masterJenisDokumen = resp.data
      resp.data.forEach(element => {
        this.optionsJenisDokumen.push({
          value: element.id,
          text: element.nama,
        })
      })
    } catch (error) {
      alert(error)
      // return
    }
    if (this.config) {
      if (this.config.initWithJenisDokumen) {
        this.selectedJenisDokumen = this.config.initWithJenisDokumen
        if (this.masterDaftarDokumen.length === 0) {
          this.tambahDokumenBaru()
        }
        this.optionsJenisDokumen = this.optionsJenisDokumen.filter(
          item => item.value === this.config.initWithJenisDokumen,
        )
      }
      if (this.config.selectedTipe) {
        this.optionsJenisDokumen = [
          { value: null, text: '-- Pilih Jenis Dokumen --' },
        ]
        this.masterJenisDokumen.forEach(element => {
          // console.log('JENIS DOK')
          // console.log(this.masterJenisDokumen)
          if (element.tipe === this.config.selectedTipe) {
            this.optionsJenisDokumen.push({
              value: element.id,
              text: element.nama,
            })
          }
        })
      }
      if (this.config.allowAddNew != null) {
        this.allowAddNew = this.config.allowAddNew
      }
      if (this.config.disableDelete != null) {
        this.disableDelete = this.config.disableDelete
      }
    }
  },
  methods: {
    async simpan(index) {
      console.log('DOKUMEN TO BE SAVED')
      console.log(this.masterDaftarDokumen[index])
      const dok = this.masterDaftarDokumen[index]

      this.showOverlay = true
      this.isProcessing = true

      if (!dok.biodata_id) {
        alert('Error: Belum ada Biodata ID')
        this.showOverlay = false
        this.isProcessing = false
        return
      }

      // upload dulu files
      const keys = Object.keys(dok.files)
      const promises = []
      keys.forEach(element => {
        // console.log(dok.files[element].name)
        // upload
        if (dok.files[element]) {
          const formData = new FormData()
          formData.append('file', dok.files[element])
          promises.push(
            upload().post(`biodata/${this.biodataID}/upload`, formData),
          )
        }
      })

      if (promises.length !== 0) {
        let results
        try {
          results = await Promise.all(promises)

          // set files to null
          const keys2 = Object.keys(dok.files)
          keys2.forEach(element => {
            dok.files[element] = null
          })
        } catch (error) {
          alert(error)
          this.showOverlay = false
          this.isProcessing = false
          return
        }
        let i = 0
        keys.forEach(element => {
          const result = results[i]
          if (!dok.data[element]) {
            Vue.set(dok.data, element, result.data.filename)
          } else {
            dok.data[element] = result.data.filename
          }
          i += 1
        })
      }

      // for (const key of keys) {
      //   // upload
      //   if (dok.files[key]) {
      //     console.log(dok.files[key].name)
      //     const formData = new FormData()
      //     formData.append('file', dok.files[key])

      //     let result
      //     try {
      //       result = await upload.post(`biodata/${this.biodataID}/upload`, formData)
      //     } catch (error) {
      //       alert(error)
      //       return
      //     }
      //     if (!dok.data[key]) {
      //       Vue.set(dok.data, key, result.data.filename)
      //     } else {
      //       dok.data[key] = result.data.filename
      //     }
      //   }
      // }

      // keys.forEach(async element => {
      //   console.log(dok.files[element].name)
      //   // upload
      //   if (dok.files[element]) {
      //     const formData = new FormData()
      //     formData.append('file', dok.files[element])

      //     let result
      //     try {
      //       result = await upload.post(`biodata/${this.biodataID}/upload`, formData)
      //     } catch (error) {
      //       alert(error)
      //       return
      //     }
      //     if (!dok.data[element]) {
      //       Vue.set(dok.data, element, result.data.filename)
      //     } else {
      //       dok.data[element] = result.data.filename
      //     }
      //   }
      // })
      // lanjut simpan metadata
      if (dok.id) {
        // patch
        try {
          const cloneDok = {
            id: dok.id,
            data: dok.data,
          }
          const result = await api().patch('biodata/dokumen', cloneDok)
          dok.verification_status = result.data.verification_status
        } catch (error) {
          // alert(`Error: ${error.response.data.message}`)
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.showOverlay = false
          this.isProcessing = false
          return
        }
      } else {
        // post
        try {
          const cloneDok = JSON.parse(JSON.stringify(dok))
          delete cloneDok.verification_status
          delete cloneDok.jenis_dokumen
          delete cloneDok.files
          const result = await api().post('biodata/dokumen', cloneDok)
          console.log('simpan dokumen')
          console.log(result)
          dok.id = result.data.id
          dok.verification_status = result.data.verification_status
        } catch (error) {
          // alert(`Error: ${error.response.data.message}`)
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.showOverlay = false
          this.isProcessing = false
          return
        }
      }
      // if dokumen is pasfoto, then we also emit event to tell main page
      // that there was update on Pasfoto, so that main page can refresh the Avatar.
      if (dok.jenis_dokumen_id === '22') {
        emitter.emit('BIODATA_FOTO_UPDATED', dok)
      }

      // emit berhasil disimpan
      emitter.emit('BIODATA_DOKUMEN_TERSIMPAN', dok)

      this.showOverlay = false
      this.isProcessing = false
      this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
    },
    tambahDokumenBaru() {
      if (!this.selectedJenisDokumen) {
        alert('Silahkan pilih jenis dokumen terlebih dahulu.')
        return
      }
      if (!this.biodataID) {
        alert('Biodata ID belum ada. Tidak dapat menambahkan Dokumen baru')
        return
      }
      const jenisDok = this.masterJenisDokumen.find(
        item => item.id === this.selectedJenisDokumen,
      )
      console.log(this.masterJenisDokumen)
      console.log(jenisDok.metadata)

      const newDok = {
        biodata_id: this.biodataID,
        jenis_dokumen_id: this.selectedJenisDokumen,
        verification_status: 0,
        data: {},
        jenis_dokumen: jenisDok,
      }
      // this.masterDaftarDokumen.splice(this.masterDaftarDokumen.length, 0, newDok)
      this.masterDaftarDokumen.push(newDok)
    },
    confirmDelete(index) {
      this.showOverlay = true
      this.indexToBeDeleted = index
    },
    onOverlayCancel() {
      this.showOverlay = false
      this.indexToBeDeleted = null
    },
    onOverlayOk() {
      this.isProcessing = true
      this.deleteDokumen()
    },
    async deleteDokumen() {
      this.showOverlay = true
      this.isProcessing = true
      const dok = this.masterDaftarDokumen[this.indexToBeDeleted]

      // await new Promise(r => setTimeout(r, 2000))
      if (dok.id) {
        // delete from db
        try {
          await api().delete(`biodata/dokumen/${dok.id}`)
        } catch (error) {
          alert(error)
          this.showOverlay = false
          this.isProcessing = false
          return
        }
      }

      this.masterDaftarDokumen.splice(this.indexToBeDeleted, 1)
      this.showOverlay = false
      this.indexToBeDeleted = null
      this.isProcessing = false

      // emit berhasil dihapus
      emitter.emit('BIODATA_DOKUMEN_DELETED', dok)

      if (dok.jenis_dokumen_id === '22') {
        emitter.emit('BIODATA_FOTO_DELETED', dok)
      }

      if (this.config.initWithJenisDokumen) {
        this.selectedJenisDokumen = this.config.initWithJenisDokumen
        if (this.masterDaftarDokumen.length === 0) {
          await new Promise(r => setTimeout(r, 100))
          this.tambahDokumenBaru()
        }
      }
    },
    verifikasiDokumen(index) {
      console.log('EMITTING VERIFIKASI')
      const dok = this.masterDaftarDokumen[index]
      // emitting VERIFIKASI will be received by BiodataDetails to show sidebar verifikasi
      emitter.emit('VERIFIKASI', dok)
    },
    showSimpan(dok) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      // userData.default_role = 'user'
      // userData.biodata_id = '23'
      if (
        userData.default_role === 'user' ||
        userData.default_role === 'member'
      ) {
        if (dok.verification_status === '1') {
          return false
        }
        if (dok.biodata_id !== userData.biodata_id) {
          return false
        }
      }
      if (userData.default_role === 'operator') {
        if (dok.verification_status === '1') {
          return false
        }
        if (this.masterBiodata.dpn_dpc_id !== userData.dpn_dpc_id) {
          return false
        }
      }
      // if (userData.default_role !== 'admin' || userData.default_role !== 'verifikator' || userData.default_role !== 'superadmin') {
      //   return false
      // }
      return true
    },
    showVerifikasi() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (
        userData.default_role !== 'verifikator' &&
        userData.default_role !== 'admin' &&
        userData.default_role !== 'superadmin'
      ) {
        return false
      }
      return true
    },
    showLogVerifikasi() {},
    showHapus(dok) {
      if (this.disableDelete) {
        return false
      }
      return this.showSimpan(dok)
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>
