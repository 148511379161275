<template>
  <div>
    <sidebar-verifikasi-dokumen />
    <sidebar-pengakhiran />
    <b-row v-if="false">
      <b-col cols="3" />
      <b-col cols="9">
        <h3 v-if="masterBiodata">
          {{ masterBiodata.nama_lengkap }}
        </h3>
      </b-col>
    </b-row>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Data Dasar</span>
        </template>
        <!-- {{masterPropinsi}}
        <br>
        jenisDok
        <br>
        {{masterJenisKartuIdentitas}} -->
        <biodata-data-dasar
          v-if="masterBiodata"
          :master-biodata.sync="masterBiodata"
          :master-negara="masterNegara.data"
          :master-jenis-kartu-identitas="masterJenisKartuIdentitas.data"
          :master-agama="masterAgama.data"
          :master-gender="masterGender.data"
          :master-struktur-organisasi="masterStrukturOrganisasi.data"
        />
        <h3 v-else>
          {{ loadingMessage }}
        </h3>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ImageIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Foto</span>
        </template>
        <!-- <biodata-pkpa /> -->
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
          <!-- <b-alert show variant="warning">Silahkan lengkapi data dasar terlebih dahulu</b-alert> -->
        </div>
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDaftarFoto"
          :biodata-i-d="masterBiodata.id"
          :config="{ initWithJenisDokumen: '22', allowAddNew: false }"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BookOpenIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Riwayat Pendidikan</span>
        </template>
        <!-- <biodata-pkpa /> -->
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDaftarIjasah"
          :biodata-i-d="masterBiodata.id"
          :config="{ initWithJenisDokumen: '23', allowAddNew: true }"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Sertifikat PKPA</span>
        </template>
        <!-- <biodata-pkpa /> -->
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDaftarPKPA"
          :biodata-i-d="masterBiodata.id"
          :config="{ initWithJenisDokumen: '1', allowAddNew: false }"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Sertifikat UPA</span>
        </template>
        <!-- <biodata-pkpa /> -->
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDaftarUPA"
          :biodata-i-d="masterBiodata.id"
          :config="{ initWithJenisDokumen: '2', allowAddNew: false }"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="PenToolIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Magang</span>
        </template>
        <!-- <biodata-pkpa /> -->
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDaftarMagang"
          :biodata-i-d="masterBiodata.id"
          :config="{ initWithJenisDokumen: '3', allowAddNew: true }"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Pengangkatan Advokat</span>
        </template>
        <!-- <biodata-pkpa /> -->
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDaftarPengangkatanAdvokat"
          :biodata-i-d="masterBiodata.id"
          :config="{ initWithJenisDokumen: '4', allowAddNew: false }"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Pengambilan Sumpah</span>
        </template>
        <!-- <biodata-pkpa /> -->
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDaftarBas"
          :biodata-i-d="masterBiodata.id"
          :config="{ initWithJenisDokumen: '5', allowAddNew: false }"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserCheckIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Nomor Induk Anggota (NIA)</span>
        </template>
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <!-- <div v-else>
          Not A/B/C
        </div> -->
        <biodata-nia
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Keanggotaan</span>
        </template>
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <!-- <div v-else>
          Not A/B/C
        </div> -->
        <element-keanggotaan
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-fotos="masterDaftarFoto"
        />
        <qr
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ThumbsUpIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Keahlian</span>
        </template>
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <!-- <div v-else>
          Not A/B/C
        </div> -->
        <element-keahlian
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ThumbsUpIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Pengakhiran</span>
        </template>
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <!-- <div v-else>
          Not A/B/C
        </div> -->
        <element-pengakhiran
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Dokumen Lainnya</span>
        </template>
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <!-- <div v-else>
          Not A/B/C
        </div> -->
        <biodata-daftar-dokumen
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
          :master-daftar-dokumen="masterDokumenLainnya"
          :biodata-i-d="masterBiodata.id"
          :config="{ selectedTipe: '2', allowAddNew: true }"
        />
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="AwardIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">QR</span>
        </template>
        <div v-if="!masterBiodata">
          {{ loadingMessage }}
        </div>
        <div v-else-if="!masterBiodata.id">
          <b-alert variant="warning" show>
            <div class="alert-body">
              <span>Silahkan lengkapi data dasar terlebih dahulu</span>
            </div>
          </b-alert>
        </div>
        <qr
          v-if="masterBiodata && masterBiodata.id"
          :master-biodata="masterBiodata"
        />
      </b-tab> -->
    </b-tabs>
  </div>
</template>
<script>
import emitter from '@/hap-sia/emitter'
import { BTabs, BTab, BAlert, BRow, BCol } from 'bootstrap-vue'
import { getCacheableApi, api } from '@/hap-sia/setup'
// import { apiWithCache, api as siaApi } from '@/hap-sia/setup2'
// import { getAllPropinsi } from '@/hap-sia/master'
// import { req } from 'vuelidate/lib/validators/common'
import SidebarVerifikasiDokumen from '@/views/hap/shared/SidebarVerifikasiDokumen.vue'
import SidebarPengakhiran from '@/views/hap/shared/SidebarPengakhiran.vue'
import BiodataDataDasar from '@/views/hap/shared/BiodataDataDasar.vue'
// import BiodataPkpa from '@/views/hap/shared/BiodataPkpa.vue'
import BiodataDaftarDokumen from '@/views/hap/shared/BiodataDaftarDokumen.vue'
import BiodataNia from '@/views/hap/shared/BiodataNia.vue'
import ElementKeanggotaan from '@/views/hap/shared/ElementKeanggotaan.vue'
import ElementKeahlian from '@/views/hap/shared/ElementKeahlian.vue'
import ElementPengakhiran from '@/views/hap/shared/ElementPengakhiran.vue'
import Qr from '@/views/hap/shared/Qr.vue'

export default {
  components: {
    BTabs,
    BTab,
    BiodataDataDasar,
    // BiodataPkpa,
    BiodataDaftarDokumen,
    BiodataNia,
    SidebarVerifikasiDokumen,
    SidebarPengakhiran,
    BAlert,
    Qr,
    ElementKeanggotaan,
    BRow,
    BCol,
    ElementKeahlian,
    ElementPengakhiran,
  },
  data() {
    return {
      masterBiodata: null,
      masterPropinsi: null,
      masterJenisDokumen: null,
      masterJenisKartuIdentitas: null,
      masterNegara: null,
      masterGender: null,
      masterStrukturOrganisasi: null,
      masterAgama: null,
      loadingMessage: 'Loading...',
      masterDaftarFoto: [],
      masterDaftarPKPA: [],
      masterDaftarUPA: [],
      masterDaftarIjasah: [],
      masterDokumenLainnya: [],
      masterDaftarMagang: [],
      masterDaftarPengangkatanAdvokat: [],
      masterDaftarBas: [],
    }
  },
  beforeMount() {
    emitter.all.clear()
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    // console.log('sleep started')
    // await new Promise(r => setTimeout(r, 2000))
    // console.log('sleep over')
    // this.masterBiodata.nama_lengkap = 'HAP Putra'

    // listen to all events from children
    // emitter.on('SIMPAN_BIODATA', (type, e) => console.log(this.masterBiodata))
    emitter.on('SIMPAN_BIODATA', this.simpanBiodata)
    const cacheApi = await getCacheableApi()
    // try {
    //   api.get('master/agama')
    // } catch (error) {
    //   alert(error)
    // }

    this.loadingMessage = 'Loading Propinsi and Jenis Dokumen...'
    try {
      ;[this.masterPropinsi, this.masterJenisDokumen] = await Promise.all([
        cacheApi.get('master/propinsi'),
        cacheApi.get('master/jenis-dokumen'),
      ])
    } catch (error) {
      alert(error.message)
      console.log(error.message)
      this.loadingMessage = 'Something went wrong. Please refresh browser'
      return
    }
    this.loadingMessage = 'Loading Jenis Kartu Identitas and Kewarganegaraan...'
    try {
      ;[this.masterJenisKartuIdentitas, this.masterNegara] = await Promise.all([
        cacheApi.get('master/jenis-kartu-identitas'),
        cacheApi.get('master/negara'),
      ])
    } catch (error) {
      alert(error.message)
      console.log(error.message)
      this.loadingMessage = 'Something went wrong. Please refresh browser'
      return
    }
    this.loadingMessage = 'Loading Gender and Agama...'
    try {
      ;[
        this.masterGender,
        this.masterAgama,
        this.masterStrukturOrganisasi,
      ] = await Promise.all([
        cacheApi.get('master/gender'),
        cacheApi.get('master/agama'),
        cacheApi.get('master/struktur-organisasi'),
      ])
    } catch (error) {
      alert(error.message)
      console.log(error.message)
      this.loadingMessage = 'Something went wrong. Please refresh browser'
      return
    }
    this.loadingMessage = 'Loading Biodata...'

    let tmpParamBiodataID = this.$route.params.id
    if (
      userData.default_role === 'member' ||
      userData.default_role === 'user'
    ) {
      if (tmpParamBiodataID && tmpParamBiodataID !== userData.biodata_id) {
        alert('Not Authorized')
        this.$router.replace({ name: 'not-authorized' })
        return
      }
      tmpParamBiodataID = userData.biodata_id
    }

    if (!tmpParamBiodataID) {
      // NEW DATA
      this.masterBiodata = {
        id: null,
        nama_lengkap: null,
        gelar_depan: null,
        gelar_belakang: null,
        agama_id: null,
        jenis_kartu_identitas_id: 1,
        nomor_kartu_identitas: null,
        gender_id: 1,
        tempat_lahir: null,
        tanggal_lahir: null,
        nomor_hp: null,
        email: null,
        nama_ktpa: null,
        warga_negara_id: 'id',
        pekerjaan: null,
        dpn_dpc_id: null,
        keahlian_lainnya: '',
        is_published: true,
        is_advokat: false,
        nia: null,
        anggota_sejak: null,
        tanggal_berakhir: null,
        is_pengakhiran: false,
        is_pengurus: false,
        is_kehormatan: false,
        created_by: null,
        tanggal_nia: null,
        daftar_alamat: [],
        daftar_dokumen: [],
      }
    } else {
      console.log('EDITING')
      try {
        const biodata = await api().get(`biodata/${tmpParamBiodataID}`)
        if (!biodata.data) {
          // alert('Tidak ada biodata ditemuakan')
          this.loadingMessage = `Tidak ditemukan biodata dengan id: ${this.$route.params.id}`
          return
        }
        this.masterBiodata = biodata.data
      } catch (error) {
        const httpStatusCode = error.response.status || undefined
        if (httpStatusCode && httpStatusCode === 401) {
          alert('Not Authorized')
          this.$router.replace({ name: 'not-authorized' })
          return
        }
        this.loadingMessage = 'Something went wrong. Please refresh browser'
        return
      }

      // const cloneBiodata = JSON.parse(JSON.stringify(this.masterBiodata))
      // console.log('CLONE')
      // console.log(cloneBiodata)
    }
    this.masterBiodata.daftar_dokumen.forEach(element => {
      // console.log('ELEMENT DAFTAR')
      // console.log(element)
      if (element.jenis_dokumen_id === '1') {
        this.masterDaftarPKPA.push(element)
      }
      if (element.jenis_dokumen_id === '2') {
        this.masterDaftarUPA.push(element)
      }
      if (element.jenis_dokumen_id === '23') {
        this.masterDaftarIjasah.push(element)
      }
      if (element.jenis_dokumen_id === '3') {
        this.masterDaftarMagang.push(element)
      }
      if (element.jenis_dokumen_id === '4') {
        this.masterDaftarPengangkatanAdvokat.push(element)
      }
      if (element.jenis_dokumen_id === '5') {
        this.masterDaftarBas.push(element)
      }
      if (element.jenis_dokumen_id === '22') {
        this.masterDaftarFoto.push(element)
      }
      if (element.jenis_dokumen.tipe === '2') {
        this.masterDokumenLainnya.push(element)
      }
    })
  },
  methods: {
    simpanBiodata() {
      console.log(this.masterBiodata)
    },
  },
}
</script>
