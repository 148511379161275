<template>
  <div>
    <!-- {{masterBiodata}} -->
    <b-card no-body class="text-center mt-0">
      <template #header>
        <b-row class="w-100">
          <b-col cols="8" class="text-left">
            <h4 class="mb-0">
              Keahlian
            </h4>
          </b-col>
          <b-col cols="4" class="text-right">
            <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
          </b-col>
        </b-row>
      </template>
      <b-card-body class="text-left">
        <b-row>
          <b-col sm="12" class="mb-1">
            <!-- toast -->
            <!-- {{ this.generalData }} -->
            <!-- <b-form-group label="NIA" label-for="nia" class="mb-2">
              <b-form-input
                v-model="masterBiodata.keahlian_lainnya"
                placeholder="NIA"
              />
            </b-form-group> -->
            <b-form-textarea
              v-model="masterBiodata.keahlian_lainnya"
              placeholder="Keahlian"
              rows="8"
            />
          </b-col>
        </b-row>
      </b-card-body>
      <template #footer>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="8" class="text-left">
              <b-button
                v-if="showSimpan()"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="simpan"
              >
                Simpan
              </b-button>
            </b-col>
            <!-- <b-col cols="4" class="text-right">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="confirmDelete"
              >
                Hapus
              </b-button>
            </b-col> -->
          </b-row>
        </b-container>
      </template>
      <b-overlay :show="showOverlay" no-wrap>
        <template #overlay>
          <div v-if="isProcessing" class="text-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p><strong id="form-confirm-label">Are you sure?</strong></p>
            <div class="d-flex">
              <b-button
                variant="outline-danger"
                class="mr-3"
                @click="onOverlayCancel"
              >
                Cancel
              </b-button>
              <b-button variant="outline-success" @click="onOverlayOk">
                OK
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BButton,
  BRow,
  BCol,
  //   BFormGroup,
  //   BFormInput,
  BOverlay,
  BSpinner,
  BContainer,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { api } from '@/hap-sia/setup'

export default {
  components: {
    BCard,
    // BCardHeader,
    BCardBody,
    // BCardText,
    BButton,
    BRow,
    BCol,
    // BFormGroup,
    // BFormInput,
    BOverlay,
    BSpinner,
    BContainer,
    // BBadge,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: ['masterBiodata'],
  data() {
    return {
      showOverlay: false,
      isProcessing: false,
    }
  },
  methods: {
    showSimpan() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (
        userData.default_role === 'user' ||
        userData.default_role === 'member'
      ) {
        return userData.biodata_id === this.masterBiodata.id
      }

      return true
    },
    async simpan() {
      this.showOverlay = true
      this.isProcessing = true
      try {
        await api().patch('biodata', {
          id: this.masterBiodata.id,
          keahlian_lainnya: this.masterBiodata.keahlian_lainnya,
        })
        this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
      } catch (error) {
        this.makeToast('danger', 'Error', error.response.data.message, true)
      }
      this.showOverlay = false
      this.isProcessing = false
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>
