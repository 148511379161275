<template>
  <div class="p-1">
    <!-- <h3>Foto</h3> -->
    <b-img v-if="fileImage" thumbnail fluid :src="fileImage" alt="Pasfoto" />
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'
import { api } from '@/hap-sia/setup'
import emitter from '@/hap-sia/emitter'

export default {
  components: {
    BImg,
  },
  props: ['masterDokumen'],
  data() {
    return {
      fileImage: null,
    }
  },
  // watch: {
  //   masterDokumen: {
  //     deep: true,
  //     handler(data) {
  //       console.log('WACH FOTO')
  //       console.log(data)
  //       this.getFoto()
  //       // if (data.nia === null || data.nia === '') {
  //       //   this.niaStatus = true
  //       // } else {
  //       //   this.niaStatus = false
  //       //   this.localData = data
  //       //   this.dataSidebar.biodata_id = data.id
  //       // }
  //     },
  //   },
  // },
  // computed: {
  //   getFotoURL() {
  //     if (this.masterDokumen.data && this.masterDokumen.data.file) {
  //       return `${imageServerUrl}/biodata/${this.masterDokumen.biodata_id}/${this.masterDokumen.data.file}`
  //     }
  //     return false
  //   },
  // },
  async mounted() {
    emitter.on('BIODATA_FOTO_UPDATED', e => {
      console.log('FOTO UPDATED')
      console.log(e)
      if (this.masterDokumen.id === e.id) {
        this.getFoto()
      }
    })
    emitter.on('VERIFICATION_UPDATED', e => {
      console.log(e)
      if (this.masterDokumen.id === e.dok_id) {
        this.getFoto()
      }
    })
    // this.fotoURL = `http://localhost:5001/sia-2021/asia-southeast2/sia/static/biodata/${this.biodataID}/${this.filename}`
    await this.getFoto()
  },
  methods: {
    async getFoto() {
      try {
        // const cacheApi = await getCacheableApi()
        if (!this.masterDokumen.data.file) {
          this.fileImage = null
        } else {
          const result = await api().get(
            `biodata/image/${this.masterDokumen.biodata_id}/${this.masterDokumen.data.file}`,
          )
          console.log(result)
          this.fileImage = result.data.url
          // jika status-nya sudah terverifikasi, update foto avatar
          if (this.masterDokumen.verification_status === '1') {
            emitter.emit('AVATAR_UPDATED', {
              biodata_id: this.masterDokumen.biodata_id,
              filename: this.masterDokumen.data.file,
              file: this.fileImage,
            })
          }
        }
      } catch (error) {
        // alert(error)
        console.log(error)
      }
    },
  },
}
</script>
