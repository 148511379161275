<template>
  <div class="p-1">
    <!-- {{masterDokumen}} -->
    <!-- <br>
    LOCAL:
    {{localDokumen}} -->
    <div v-if="masterDokumen.jenis_dokumen">
      <div
        v-for="(val, name) in masterDokumen.jenis_dokumen.metadata.properties"
        :key="name"
        :title="name"
        :isVisible="true"
      >
        <!-- {{name}}: {{val}} -->
        <div v-if="masterDokumen.data">
          <!-- {{masterDokumen.data[name]}} -->
          <b-row>
            <b-col sm="12">
              <b-form-group :label="titleCase(name)" :label-for="name">
                <b-form-input
                  v-if="getInputType(name) === 'text'"
                  v-model="masterDokumen.data[name]"
                  :placeholder="name"
                />
                <flat-pickr
                  v-if="getInputType(name) === 'date'"
                  v-model="masterDokumen.data[name]"
                  class="form-control"
                />
                <div v-if="getInputType(name) === 'file'">
                  <div v-if="masterDokumen.data[name]">
                    <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    class="btn-icon"
                  >
                    <feather-icon icon="ArchiveIcon" />
                  </b-button> -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      class="mb-1"
                      :disabled="btnDownloadDisabled"
                      @click="download(name)"
                    >
                      <b-spinner
                        v-show="btnDownloadDisabled"
                        small
                        type="grow"
                      />
                      <feather-icon icon="DownloadCloudIcon" class="mr-50" />
                      <span class="align-middle">Download</span>
                    </b-button>
                    <!-- <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-success"
                    class="mb-1"
                    @click="download"
                  >
                    <feather-icon
                      icon="DownloadCloudIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Download</span>
                  </b-button> -->
                    ({{ masterDokumen.data[name] }})
                  </div>
                  <b-form-file v-model="files[name]" plain />
                  <!-- <div class="mt-1">Selected file: {{ files[name] ? files[name].name : '' }}</div> -->
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { download } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    flatPickr,
    BFormFile,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: ['masterDokumen'],
  data() {
    return {
      files: {},
      btnDownloadDisabled: false,
      // localDokumen: this.masterDokumen,
    }
  },
  created() {
    // this.localDokumen.files = { file: {} }
    this.masterDokumen.files = this.files
    this.reOrderMetadata(this.masterDokumen.jenis_dokumen.metadata)
  },
  methods: {
    titleCase(propName) {
      return propName
        .split('_')
        .map(item => item.charAt(0).toUpperCase() + item.substring(1))
        .join(' ')
    },
    getInputType(propName) {
      const name = String(propName)
      // alert(name)
      if (name.includes('file')) {
        return 'file'
      }
      if (name.includes('tanggal')) {
        return 'date'
      }
      if (name.includes('file')) {
        return 'file'
      }
      return 'text'
    },
    async download(filename) {
      // const fileUrl = `${fileServerUrl}/biodata/${this.masterDokumen.biodata_id}/${this.masterDokumen.data[filename]}`
      // window.open(fileUrl)

      // alert(this.masterDokumen.data[filename])

      try {
        this.btnDownloadDisabled = true
        const result = await download().get(
          `biodata/download/${this.masterDokumen.biodata_id}/${this.masterDokumen.data[filename]}`,
        )
        console.log(result)
        // this.fileQR = result.data.url
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.masterDokumen.data[filename]) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.btnDownloadDisabled = false
    },
    reOrderMetadata(metaData) {
      const meta = metaData
      const keys = Object.keys(meta.properties)
      // console.log('JAPRUT')
      // console.log(keys)

      const newProps = {}
      const newKeys = {}
      // {prop_name: 1}

      keys.forEach(element => {
        // console.log('a: ', element)
        // switch (element) {
        //   case element.includes('file'):
        //     console.log(element)
        //     newProps[element] = 38
        //     break
        //   default:
        //     newProps[element] = 51
        //     break
        // }
        if (element.includes('_id')) {
          return
        }
        if (element.includes('file')) {
          console.log(element)
          newProps[element] = 99
          newKeys[99] = element
          // newKeys.push({ 99: element })
          /* eslint-disable-next-line no-continue */
          return
        }
        if (element.includes('perguruan_tinggi')) {
          newProps[element] = 1
          return
        }
        if (element.includes('bidang_ilmu')) {
          newProps[element] = 2
          return
        }
        if (element.includes('jenjang')) {
          newProps[element] = 3
          return
        }
        if (element.includes('tanggal_kelulusan')) {
          newProps[element] = 4
          return
        }
        if (element.includes('nomor')) {
          newProps[element] = 1
          return
        }
        if (element.includes('tanggal')) {
          newProps[element] = 2
          return
        }
        if (element.includes('penerbit')) {
          newProps[element] = 3
          return
        }
        if (element.includes('nama_kantor')) {
          newProps[element] = 31
          return
        }
        if (element.includes('alamat')) {
          newProps[element] = 32
          return
        }
        if (element.includes('propinsi')) {
          newProps[element] = 33
          return
        }
        if (element.includes('kota')) {
          newProps[element] = 34
          return
        }
        if (element.includes('kode')) {
          newProps[element] = 35
          return
        }
        if (element.includes('telepon')) {
          newProps[element] = 36
          return
        }
        if (element.includes('faksimili')) {
          newProps[element] = 36
          return
        }
        if (element.includes('web')) {
          newProps[element] = 37
          return
        }
        if (element.includes('pimpinan')) {
          console.log(element)
          newProps[element] = 38
          return
        }
        if (element.includes('pendamping')) {
          newProps[element] = 39
          return
        }
        newProps[element] = 51
        // newKeys[51] = element
        // newKeys.push({ 51: element })
      })
      // console.log('NEW PROPS')
      // console.log(newProps)
      // console.log('NEW KEYS')
      // console.log(newKeys)
      // const sortedKeys = Object.keys(newKeys)
      // sortedKeys.sort()

      // const tmpProps = {}
      // sortedKeys.forEach(element => {
      //   console.log(element)
      //   if (meta.properties[newKeys[element]]) {
      //     tmpProps[newKeys[element]] = meta.properties[newKeys[element]]
      //   }
      // })
      const sortable = []
      const sortKeys = Object.keys(newProps)
      sortKeys.forEach(element => {
        sortable.push([element, newProps[element]])
      })
      // for (var item in newProps) {
      //     sortable.push([item, newProps[item]])
      // }
      sortable.sort((a, b) => a[1] - b[1])

      // console.log('SORTABLE')
      // console.log(sortable)

      const tmpProps = {}
      sortable.forEach(item => {
        // console.log(item[0])
        tmpProps[item[0]] = meta.properties[item[0]]
      })
      meta.properties = tmpProps
      // const ordered = Object.keys(newKeys).sort().reduce(
      //   (obj, key) => {
      //     obj[key] = newKeys[key]
      //     return obj
      //   },
      //   {},
      // )
      // console.log('ORDERED KEYS')
      // console.log(ordered)
      // const allowedProps = [
      //   'nomor',
      //   'file',
      // ]
      // const reOrderedMeta = meta
      // console.log('META')
      // console.log(meta)

      // const tmpProps = {}
      // allowedProps.forEach(val => {
      //   if (meta.properties[val]) {
      //     tmpProps[val] = meta.properties[val]
      //   }
      // })
      // console.log('TMP PROPS')
      // console.log(tmpProps)
      // meta.properties = tmpProps
      return meta
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
