<template>
  <div>
    <b-card no-body class="text-center mt-1">
      <template #header>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="8" class="text-left">
              <h4 class="mb-0">
                QR Code
              </h4>
            </b-col>
            <b-col cols="4" class="text-right">
              <!-- <b-badge pill variant="success">Terverifikasi</b-badge> -->
            </b-col>
          </b-row>
        </b-container>
      </template>
      <b-card-body class="text-left">
        <b-row>
          <b-col sm="12" class="text-center">
            <!-- <b-avatar :src="fileQR" square /> -->
            <b-img v-if="fileQR" :src="fileQR" fluid alt="Fluid image')" />
          </b-col>
        </b-row>
      </b-card-body>
      <template #footer>
        <b-container fluid class="w-100">
          <b-row>
            <b-col cols="8" class="text-left">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="download"
              >
                Download
              </b-button>
            </b-col>
            <!-- <b-col cols="4" class="text-right">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="confirmDelete"
              >
                Hapus
              </b-button>
            </b-col> -->
          </b-row>
        </b-container>
      </template>
      <b-overlay :show="showOverlay" no-wrap>
        <template #overlay>
          <div v-if="isProcessing" class="text-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
          <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p><strong id="form-confirm-label">Are you sure?</strong></p>
            <div class="d-flex">
              <b-button
                variant="outline-danger"
                class="mr-3"
                @click="onOverlayCancel"
              >
                Cancel
              </b-button>
              <b-button variant="outline-success" @click="onOverlayOk">
                OK
              </b-button>
            </div>
          </div>
        </template>
      </b-overlay>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BOverlay,
  BSpinner,
  BContainer,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { api, download } from '@/hap-sia/setup'

export default {
  components: {
    BCard,
    // BCardHeader,
    BCardBody,
    // BCardText,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BSpinner,
    BContainer,
    BImg,
    // BBadge,
  },
  directives: {
    Ripple,
  },
  props: ['masterBiodata'],
  data() {
    return {
      localBiodata: this.masterBiodata,
      showOverlay: false,
      isProcessing: false,
      fileQR: null,
    }
  },
  async mounted() {
    try {
      const result = await api().get(
        `qr/code/${this.masterBiodata.id}/data-url`,
      )
      // const result = await api().get(
      //   'biodata/image/161/161_1a4ce5896b23ad31aa58.jpg',
      // )
      // console.log(result)
      this.fileQR = result.data.url
    } catch (error) {
      alert(error)
    }
  },
  methods: {
    async download() {
      // alert(filename)
      this.showOverlay = true
      this.isProcessing = true
      try {
        const result = await download().get(
          `qr/code/${this.masterBiodata.id}/download`,
        )
        console.log(result)
        // this.fileQR = result.data.url
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `qr_${this.masterBiodata.id}.png`) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.showOverlay = false
      this.isProcessing = false
    },
  },
}
</script>
