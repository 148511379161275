<template>
  <div>
    <!-- <h1>Data Dasar</h1> -->
    <!-- masterBiodata: <br />
    {{ this.masterBiodata }} -->
    <!-- <br />
    localBiodata: <br />
    {{ this.localBiodata }} -->
    <!-- {{ avatarFile }} -->
    <b-card>
      <b-form class="mt-2" name="data-dasar-form">
        <b-row>
          <b-col sm="12">
            <div class="mb-2">
              <b-avatar :src="avatarFile" size="10rem" />
            </div>
          </b-col>
          <b-col sm="12">
            <!-- toast -->
            <!-- {{ this.generalData }} -->
            <b-form-group
              label="Nama Lengkap (Sesuai Ijazah Terakhir)"
              label-for="account-name"
            >
              <b-form-input
                v-model="localBiodata.nama_lengkap"
                placeholder="Nama Lengkap"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Gelar Depan (Contoh: Drs., Ir., Dr.)"
              label-for="gelar_depan"
            >
              <b-form-input
                v-model="localBiodata.gelar_depan"
                placeholder="Gelar Depan"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Gelar Belakang (Contoh: S.H., M.H.)"
              label-for="gelar_belakang"
            >
              <b-form-input
                v-model="localBiodata.gelar_belakang"
                placeholder="Gelar Belakang"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Kewarganegaraan" label-for="kewarganegaraan">
              <b-form-select
                v-model="localBiodata.warga_negara_id"
                :options="optionsWargaNegara"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Kartu Identitas" label-for="kartu-identitas">
              <b-form-select
                v-model.number="localBiodata.jenis_kartu_identitas_id"
                :options="optionsJenisKartuIdentitas"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Nomor Kartu Identitas"
              label-for="nomor-kartu-identitas"
            >
              <b-form-input
                v-model="localBiodata.nomor_kartu_identitas"
                placeholder="Nomor Kartu Identitas"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Agama" label-for="agama">
              <b-form-select
                v-model="localBiodata.agama_id"
                :options="optionsAgama"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Gender" label-for="gender">
              <b-form-select
                v-model="localBiodata.gender_id"
                :options="optionsGender"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Tempat Lahir" label-for="tempat-lahir">
              <b-form-input
                v-model="localBiodata.tempat_lahir"
                placeholder="Tempat Lahir"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Tanggal Lahir" label-for="tanggal-lahir">
              <flat-pickr
                id="tanggal-lahir"
                v-model="localBiodata.tanggal_lahir"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Asal DPC (Sesuai Domisili Kantor)"
              label-for="dpn-dpc"
            >
              <b-form-select
                v-model="localBiodata.dpn_dpc_id"
                :options="optionsStrukturOrganisasi"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Nomor HP atau nomor lain yang dapat dihubungi"
              label-for="nomor-hp"
            >
              <b-form-input
                v-model="localBiodata.nomor_hp"
                placeholder="Nomor HP"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="Email" label-for="email">
              <b-form-input v-model="localBiodata.email" placeholder="E-Mail" />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Nama yang akan dicetak pada KTPA"
              label-for="nama-ktpa"
            >
              <b-form-input
                v-model="localBiodata.nama_ktpa"
                placeholder="Nama KTPA"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <biodata-alamat
              :master-daftar-alamat="localBiodata.daftar_alamat"
              :biodata-id="localBiodata.id"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="btnSimpanDisabled"
              @click="simpan"
            >
              <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
              <span class="sr-only">Loading...</span>
              Simpan
            </b-button>
            <!-- <b-button
              v-if="!dataMasterBiodata.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="tambah"
            >
              Tambah
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BButton,
  BSpinner,
  BAvatar,
  // BFormFile,
  // BButton, BForm, BFormGroup, BFormGroup, BRow, BFormGroup, BCard,
  // BCardText,
  // BMedia, BMediaAside,
  // BMediaBody,
  // BLink, BImg, BFormSelect, BOverlay, BToast,
} from 'bootstrap-vue'
import emitter from '@/hap-sia/emitter'
import { api } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
// import useBiodatas from '@/views/hap/biodata/useBiodatas'
import BiodataAlamat from './BiodataAlamat.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    flatPickr,
    BButton,
    BiodataAlamat,
    BSpinner,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: [
    'masterBiodata',
    'masterNegara',
    'masterJenisKartuIdentitas',
    'masterAgama',
    'masterGender',
    'masterStrukturOrganisasi',
  ],
  data() {
    // const { avatarFile } = useBiodatas()

    return {
      localBiodata: this.masterBiodata,
      // filenameAvatar: null,
      optionsWargaNegara: [{ value: null, text: '--Pilih Kewarganegaraan--' }],
      optionsJenisKartuIdentitas: [
        { value: null, text: '--Pilih Jenis Kartu Identitas--' },
      ],
      optionsAgama: [{ value: null, text: '--Pilih Agama--' }],
      optionsGender: [{ value: null, text: '--Pilih Gender--' }],
      optionsStrukturOrganisasi: [{ value: null, text: '--Pilih DPN/DPC--' }],
      btnSimpanSpinnerShow: false,
      btnSimpanDisabled: false,
      avatarFile: null,
    }
  },
  // computed: {
  //   getFotoURL() {
  //     return `${imageServerUrl}/biodata/${this.masterBiodata.id}/${this.filenameAvatar}`
  //   },
  // },
  async mounted() {
    emitter.on('AVATAR_UPDATED', async e => {
      this.avatarFile = e.file
    })
    // handle event when Pasfoto is updated
    // emitter.on('BIODATA_FOTO_UPDATED', e => {
    //   console.log('FOTO UPDATED')
    //   console.log(e)
    //   this.filenameAvatar = e.data.file
    //   this.getAvatar()
    // })
    // emitter.on('BIODATA_FOTO_DELETED', e => {
    //   console.log('FOTO DELETED')
    //   if (this.filenameAvatar === e.data.file) {
    //     // this.filenameAvatar = ''
    //     // this.getAvatar()
    //     this.fileAvatar = null
    //   }
    // })
    // find latest pasfoto and update the avatar
    // const fotos = this.masterBiodata.daftar_dokumen.filter(
    //   dok => dok.jenis_dokumen_id === '22',
    // )
    // if (fotos.length > 0) {
    //   this.filenameAvatar = fotos[0].data.file
    //   this.getAvatar()
    // }
    for (let i = 0; i < this.masterNegara.length; i += 1) {
      this.optionsWargaNegara.push({
        value: this.masterNegara[i].id,
        text: this.masterNegara[i].nama,
      })
    }
    for (let i = 0; i < this.masterJenisKartuIdentitas.length; i += 1) {
      this.optionsJenisKartuIdentitas.push({
        value: this.masterJenisKartuIdentitas[i].id,
        text: this.masterJenisKartuIdentitas[i].nama,
      })
    }
    for (let i = 0; i < this.masterAgama.length; i += 1) {
      this.optionsAgama.push({
        value: this.masterAgama[i].id,
        text: this.masterAgama[i].nama,
      })
    }
    for (let i = 0; i < this.masterGender.length; i += 1) {
      this.optionsGender.push({
        value: this.masterGender[i].id,
        text: this.masterGender[i].nama,
      })
    }
    for (let i = 0; i < this.masterStrukturOrganisasi.length; i += 1) {
      this.optionsStrukturOrganisasi.push({
        value: this.masterStrukturOrganisasi[i].id,
        text: this.masterStrukturOrganisasi[i].nama,
      })
    }
    // this.masterNegara.data.forEach(function (item, index) {
    //   that.optionsWargaNegara.value.push({
    //     value: item.id,
    //     text: item.nama,
    //   })
    // })
  },
  methods: {
    async simpan() {
      console.log('simpan')
      this.btnSimpanSpinnerShow = true
      this.btnSimpanDisabled = true
      let results
      if (this.localBiodata.id) {
        console.log('PATCH')
        try {
          const tmpBiodata = JSON.parse(JSON.stringify(this.localBiodata))
          delete tmpBiodata.is_advokat
          delete tmpBiodata.nia
          delete tmpBiodata.anggota_sejak
          delete tmpBiodata.tanggal_berakhir
          delete tmpBiodata.is_pengakhiran
          delete tmpBiodata.is_pengurus
          delete tmpBiodata.is_kehormatan
          delete tmpBiodata.tanggal_nia
          delete tmpBiodata.created_by
          delete tmpBiodata.created_at
          delete tmpBiodata.updated_by
          delete tmpBiodata.updated_at
          delete tmpBiodata.deleted_at
          delete tmpBiodata.deleted_by
          delete tmpBiodata.is_deleted
          delete tmpBiodata.is_nia_unlocked
          delete tmpBiodata.kab_kota_keanggotaan_id
          delete tmpBiodata.propinsi_keanggotaan_id
          delete tmpBiodata.keahlian
          delete tmpBiodata.tanggal_mulai
          delete tmpBiodata.tanggal_berakhir
          delete tmpBiodata.daftar_dokumen
          results = await api().patch('biodata', tmpBiodata)
        } catch (error) {
          // console.log(error.response.data.message)
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.btnSimpanSpinnerShow = false
          this.btnSimpanDisabled = false
          return
        }
      } else {
        try {
          const tmpBiodata = JSON.parse(JSON.stringify(this.localBiodata))
          delete tmpBiodata.id
          delete tmpBiodata.is_advokat
          delete tmpBiodata.nia
          delete tmpBiodata.anggota_sejak
          delete tmpBiodata.tanggal_berakhir
          delete tmpBiodata.is_pengakhiran
          delete tmpBiodata.is_pengurus
          delete tmpBiodata.is_kehormatan
          delete tmpBiodata.tanggal_nia
          delete tmpBiodata.created_by
          delete tmpBiodata.created_at
          delete tmpBiodata.daftar_dokumen
          results = await api().post('biodata', tmpBiodata)
          this.localBiodata.id = results.data.id
          // this.$router.go(0)
          // TODO: must update localstorage userData
          const userData = JSON.parse(localStorage.getItem('userData'))
          if (
            userData.default_role === 'member' ||
            userData.default_role === 'user'
          ) {
            userData.biodata_id = results.data.id
            localStorage.setItem('userData', JSON.stringify(userData))
          }
        } catch (error) {
          // console.log(error.response.data.message)
          this.makeToast('danger', 'Error', error.response.data.message, true)
          this.btnSimpanSpinnerShow = false
          this.btnSimpanDisabled = false
          return
        }
      }
      // await new Promise(r => setTimeout(r, 2000))
      this.btnSimpanSpinnerShow = false
      this.btnSimpanDisabled = false
      this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
      // fire an event
      // emitter.emit('SIMPAN_BIODATA', this.localBiodata)
      emitter.emit('SIMPAN_BIODATA')
    },
    // async getAvatar() {
    //   try {
    //     const result = await api().get(
    //       `biodata/image/${this.masterBiodata.id}/${this.filenameAvatar}`,
    //     )
    //     console.log(result)
    //     this.fileAvatar = result.data.url
    //   } catch (error) {
    //     // alert(error)
    //     console.log(error)
    //   }
    // },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
