<template>
  <div>
    <div v-if="status === '1'">
      <b-badge pill variant="success">
        Terverifikasi
      </b-badge>
    </div>
    <div v-else-if="status === '0'">
      <b-badge pill variant="warning">
        Belum Verifikasi
      </b-badge>
    </div>
    <div v-else-if="status === '-1'">
      <b-badge pill variant="danger">
        Gagal Verifikasi
      </b-badge>
    </div>
    <div v-else>
      <b-badge pill variant="info">
        Baru - Belum disimpan
      </b-badge>
    </div>
  </div>
</template>
<script>
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: ['status'],
  data() {
    return {}
  },
}
</script>
